import axios from 'axios'
import SocketioService from "@/services/socketioService.js";

let ioObj = null;
let interval = undefined;

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const apiClient = axios.create({
    baseURL: 'http://192.168.88.9/enat/testo3/ajax.php',
    timeout: 2000,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

// Step-2: Create request, response & error handlers
const requestHandler = request => {
    // Token will be dynamic so we can use any app-specific way to always   
    // fetch the new token before making the call
    //request.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMTIzNDU2Nzg5IiwibmFtZSI6IlNhbXBsZSIsImlhdCI6MTUxNjIzODIzfQ.ZEBwz4pWYGqgFJc6DIi7HdTN0z5Pfs4Lcv4ZNwMr1rs';
    request.headers = { Accept: 'application/json', 'Content-Type': 'application/json' }
    return request;
};

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const apiClient2 = axios.create({
    baseURL: 'http://192.168.88.11:3000/api/opc/browse',
    timeout: 2000,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

const apiClient3 = axios.create({
    baseURL: 'http://192.168.88.11:3000/api',
    timeout: 10000,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

const responseHandler = response => {
    if (response.status === 401) {
        window.location = '/login';
    }

    return response;
};

const errorHandler = error => {
    return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
apiClient.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

apiClient.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default {
    apiClient,
    apiClient2,
    apiClient3,
    getSystemStatus() {
        return apiClient.get('?system=dateTime&system=status&system=copyright')
        // apiClient.get('?system=dateTime&system=status&system=copyright')
        //     .then((response) => {
        //         const sysStat = response.data;
        //         this.$store.dispatch('setSystemStatus', sysStat);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    },
    getBedienungMenu() {
        return apiClient.get('?menuContent=mainMenu')
    },
    getAlarmLog() {
        return apiClient.get('?systemLogs=alarm')
    },
    getParameterList() {
        return apiClient.get('?stationData=liste')
    },
    getParameterList2() {
        return apiClient2.get()
    },
    getConfigIO() {
        return apiClient3.get('/config/types')
    },
    getConfigIOData() {
        return apiClient3.get('/config/ioData')
    },
    getLogData() {
        return apiClient3.get('/system/log')
    },
    getStatusIO(callback) {
        SocketioService.getStatusIO((response) => {
            console.log(response);
            callback(response);
            // this.$store.dispatch("io/addIOsObj", response);

            // this.loading = false;
        });
    },
    readStatusIO() {
        return ioObj;
    },
    setInterval(state) {
        if (state) {
            interval = setInterval(() => {
                this.getStatusIO((response) => {
                    ioObj = response;
                    // console.log(ioObj);
                });
            }, 3000);
        } else {
            clearInterval(interval);
        }
    }
}